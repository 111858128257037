const Videos = () => {
  return (
    <>
        <div className='videoHighlights mainVideo'>
          <iframe src="https://www.youtube.com/embed/x_yrfm1Ef-Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      <div className="divHighlights">
        <div className='videoHighlights'>  
          <iframe src="https://www.youtube.com/embed/bQTc72t64z0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div> 
        <div className='videoHighlights'>    
          <iframe src="https://www.youtube.com/embed/5BAofIl5VZI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className='videoHighlights'>
          <iframe src="https://www.youtube.com/embed/FaPc9wguDz4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className='videoHighlights'> 
          <iframe src="https://www.youtube.com/embed/U7DjLdyv5qI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
    </>
  )
}

export default Videos
