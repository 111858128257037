const Countries = () => {
  return (
    <div className="dark countriesDiv">
      <a href="https://www.solutionbox.com.ar/">
        <img src="images/argentina.png" alt="Solution Box Argentina" className="imgCountries"/>
        <span className="countryName">ARGENTINA</span>
      </a>
      <a href="https://www.solutionboxusa.com">
        <img src="images/usa.png" alt="Solution Box USA" className="imgCountries"/>
        <span className="countryName">USA</span>
      </a>
      <a href="https://www.solutionbox.com.uy/">
        <img src="images/rou.png" alt="Solution Box Uruguay" className="imgCountries"/>
        <span className="countryName">URUGUAY</span>
      </a>
      <a href="https://www.solutionbox.com.do/">
        <img src="images/RDominicana.png" alt="Solution Box R. Dominicana" className="imgCountries"/>
        <span className="countryName">R. DOMINICANA</span>
      </a>
      <a href="https://www.solutionbox.com.cr/">
         <img src="images/costarica.png" alt="Solution Box Costa Rica" className="imgCountries"/>
        <span className="countryName">COSTA RICA</span>
      </a>
      <a href="https://www.solutionbox.com.xxxxxxxxxx/">
         <img src="images/panama.png" alt="Solution Box Panama" className="imgCountries"/>
        <span className="countryName">PANAMÁ</span>
      </a>
      <a href="https://www.solutionbox.com.xxxxxxxxxx/">
         <img src="images/honduras.png" alt="Solution Box Honduras" className="imgCountries"/>
        <span className="countryName">HONDURAS</span>
      </a>
      <a href="https://www.solutionbox.com.xxxxxxxxxx/">
         <img src="images/elsalvador.png" alt="Solution Box El Salvador" className="imgCountries"/>
        <span className="countryName">EL SALVADOR</span>
      </a>
      <a href="https://www.solutionbox.com.xxxxxxxxxx/">
         <img src="images/paraguay.png" alt="Solution Box Paraguay" className="imgCountries"/>
        <span className="countryName">PARAGUAY</span>
      </a>
      <a href="https://www.solutionbox.com.xxxxxxxxxx/">
         <img src="images/guatemala.png" alt="Solution Box Guatemala" className="imgCountries"/>
        <span className="countryName">GUATEMALA</span>
      </a>
    </div>
  )
}

export default Countries
