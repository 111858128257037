import NavBar from './components/header/Navbar'
import Carrousel from './components/carrousel/Carrousel'
import Highlights from './components/highlights/Highlights'
import Footer from './components/footer/Footer'
import AboutUs from './components/info/AboutUs'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

const App = () => {
  return (
    <BrowserRouter>
      <NavBar/>
      <Routes>
        <Route exact path='/' element={[<Carrousel/>, <Highlights/>]}/>
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='*' element= { <Navigate to ="/" /> } />
      </Routes>
      <Footer/>
    </BrowserRouter>   
  )
}

export default App;