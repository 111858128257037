import Contact from './Contact'
import Info from './Info'
const Footer = () => {
  return (
    <div className='divFooter'>
        <Contact/>
        <Info/>
    </div>
  )
}

export default Footer