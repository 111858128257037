const AboutUs = () => {
  return (
    <div className="aboutDiv">
      <h1 className="aboutTitle">About Us</h1>
      <p>
        Solution Box began operations in Argentina with a presence in segments 
        such as connectivity, storage, security, retail and software, 
        which has led them to become one of the main distributors in Latin America and the Caribbean. 
        In 2011 they made the commercial opening in the United States; Uruguay in 2016, Dominican Republic in 2017, 
        Costa Rica in 2018 and recently with the opening of its new headquarters in Guatemala and Honduras 
        forming a company that today has more than 200 employees.
      </p>
      <p>
        Solution Box with its broad portfolio entered a large number of economic sectors, 
        including Banking, Hospitality, Public and Private Sector, integrators, 
        among many others who have trusted over the years in the company that in addition to quality 
        in Its service includes brand integrations such as APC by Schneider, Garmin, Watchguard, Ubiquiti Networks, 
        Grandstream Networks, Axis, Qnap, Samsung, Apple, Hewlett Packard Inc and Enterprise, among many more.
      </p>
      <p>
        The development of IoT stands out, a project in which they are represented by Itokii, 
        which has allowed them to enter industries such as agriculture, fast food stores, airports, among others. 
        It also allows from controlling an irrigation system in a field, controlling vibration and inclination 
        for perimeter security to measuring power consumption and then reporting the data to the cloud through a Gateway.
      </p>
      <p>
        Solution Box is known for being a distributor that helps, supports, educates and with which it is easy to do business. 
        As expressed by Eduardo Bicerne, General Manager of the company, 
        “NO is not an option, we always solve the needs of our customers offering our best service. 
        Among our high quality customer service, education is also highlighted as one of our competitive advantages.
      </p>
      <p>
        Solution Box offer different webinars, trainings and certifications for each of the brands represented. 
        Our goal is to provide Integral SOLUTIONS to our clients.
      </p>
      <p>
        We are Distributors of: APC by Schneider , Aruba Networks, Apple, HPE , Hewlett Packard Enterprise, 
        GARMIN, Cambium Networks, Grandstream, Huawei, Itokii, JABRA, Pure Storage, QNAP, Sangoma, 
        Samsung, Tp-link, Ubiquiti, Watchguard, Yealink, Yeastar,Samsung Digital, GO PRO, DJI
      </p>
      <p>
        For more information please Contact us: info@solutionbox.com.hk
      </p>
    </div>
  )
}

export default AboutUs
