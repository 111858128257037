import Carousel from 'react-bootstrap/Carousel'

const CarrouselItem = () => {
  return (
    <>
    <Carousel>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src="images/Azza.jpg"
          alt="Azza"
        />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"
          src="images/Cambium.jpg"
          alt="Cambium"
        />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100"                 
          src="images/Fiberhome.jpg"
          alt="Fiberhome"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/grandstream.jpg"
          alt="granstream"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/hikvision.jpg"
          alt="hikvision"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/huawei.jpg"
          alt="huawei"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/mikrotik.jpg"
          alt="mikrotik"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/NSX.jpg"
          alt="NSX"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/Q-BOX.jpg"
          alt="Q-BOX"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/tplink.jpg"
          alt="tplink"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/ubiquiti.jpg"
          alt="ubiquiti"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src="images/yealink.jpg"
          alt="yealink"
        />
      </Carousel.Item>
    </Carousel>
    </>
  )
}

export default CarrouselItem