const MarketLeading = () => {
  return (
    <>
      <h1 className='SecTitle'>MARKET-LEADING BRANDS AVAILABLE IN <strong>SOLUTION BOX</strong></h1>
      <div className='brandsDiv'>
        <img src="images/azza.gif" alt="azza"/>
        <img src="images/cambium.gif" alt="cambium"/>
        <img src="images/fiberhome.gif" alt="fiberhome"/>
        <img src="images/grandstream.gif" alt="grandstream"/>
        <img src="images/hikvision.gif" alt="hikvision"/>
        <img src="images/huawei.gif" alt="huawei"/>
        <img src="images/mikrotik.gif" alt="mikrotik"/>
        <img src="images/nsx.gif" alt="NSX"/>
        <img src="images/qbox.gif" alt="Q-BOX"/>
        <img src="images/tplink.gif" alt="tplink"/>
        <img src="images/ubiquiti.gif" alt="ubiquiti"/>
        <img src="images/yealink.gif" alt="yealink"/>
      </div>
    </>
  )
}

export default MarketLeading
