import MarketLeading from './MarketLeading'
import Videos from './Videos'

const Highlights = () => {
  return (
    <div>
      <h1 className="SecTitle"><strong>SOLUTION BOX</strong> HIGHLIGHTS</h1>
      <Videos/>
      <MarketLeading/>
    </div>
  )
}

export default Highlights
