const Info = () => {
  return (
    <>
      <div className='divContact'>
        <div className='subTitleContact'>
          <h6>SOLUTION BOX</h6>
          <span>About Us</span>
        </div>

        <div className='subTitleContact'>
          <h6>MORE</h6>
          <a href="https://www.solutionbox.com.ar/" target="_blank" rel='noreferrer'>Argentina</a> 
          <a href="https://www.solutionbox.com.do" target="_blank" rel='noreferrer'>República Dominicana</a>
          <a href="https://www.solutionboxusa.com" target="_blank" rel='noreferrer'>USA</a>
          <a href="https://www.solutionbox.com.pa/" target="_blank" rel='noreferrer'>Panama</a>
          <a href="https://www.solutionbox.com.hk/" target="_blank" rel='noreferrer'>Hong Kong</a>
          <a href="http://www.solutionbox.com.gt/" target="_blank" rel='noreferrer'>Guatemala</a> 
          <a href="https://www.solutionbox.com.sv" target="_blank" rel='noreferrer'>El Salvador</a>
          <a href="https://www.solutionbox.com.hn" target="_blank" rel='noreferrer'>Honduras</a>
          <a href="https://www.solutionbox.com.py" target="_blank" rel='noreferrer'>Paraguay</a>
          <a href="https://www.solutionbox.com.uy" target="_blank" rel='noreferrer'>Uruguay</a>
          <a href=" https://www.solutionbox.cr" target="_blank" rel='noreferrer'>Costa Rica</a>
        </div>

        <div className='subTitleContact'>
          <h6>CONTACT</h6>
          <a href="mailto:info@solutionbox.com.hk">info@solutionbox.com.hk</a>
        </div>
    
      </div>
        <h6>© Solution Box</h6>
    </>
  )
}

export default Info
